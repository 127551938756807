import { createApp } from 'vue'
import Toast, { POSITION } from 'vue-toastification'
import App from './App.vue'
import router from './router'
import '@/assets/reset.css'
import '@/assets/stylesheet.css'
import 'vue-toastification/dist/index.css'

const userLogged = JSON.parse(window.localStorage.getItem('user'))

const app = createApp(App)
app.use(router)
app.use(Toast, { position: POSITION.TOP_RIGHT })
app.use(userLogged)
app.mount('#app')

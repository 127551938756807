<template>
<!DOCTYPE html>
<html>
    <head>
        <title>Rodada de prêmios! Sua sorte chegou!</title>
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, user-scalable=yes, initial-scale=1.0, maximum-scale=10, minimum-scale=1.0">
        <link rel="shortcut icon" href="./assets/img/favicom-redeamerica.png" />
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,500&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,500&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,500;1,700&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,500;1,700&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,500;1,700;1,900&display=swap" rel="stylesheet">
    </head>
    <body>
        <main class="principal">
            <header-logged />
            <section class="principal__conteudo">
          <router-view />
        </section>
      </main>
    </body>
  </html>
</template>
<script>
// import { services } from './services/user'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { setCurrentUser } from './store/user.js'
import HeaderLogged from './components/HeaderLogged'
// import services from './services'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const user = JSON.parse(window.localStorage.getItem('user'))

    watch(() => route.path, async () => {
      if (route.meta.hasAuth) {
        if (!user) {
          router.push({ name: 'Login' })
          return
        }
      }

      // const { data } = services.user.user.getMe()
      setCurrentUser(user)
      // console.log(user)
      return {
        user
      }
      /*
      } else {
        const storageUser = typeof window.localStorage.getItem('user') !== 'undefined' && window.localStorage.getItem('user') !== null ? window.localStorage.getItem('user') : '{}'
        if (storageUser) {
          const user = JSON.parse(storageUser)
          setCurrentUser(user)
          return {
            user
          }
        }
      }
      */
    })
  },
  components: { HeaderLogged }
}
</script>

import {
  createRouter,
  createWebHistory
} from 'vue-router'

const Home = () => import('../views/Home/index.vue')
const HomeLogado = () => import('../views/Home/logado.vue')
const Login = () => import('../views/Login/index.vue')
const Assinatura = () => import('../views/Assinatura/index.vue')
const FormaPagto = () => import('../views/Assinatura/forma-pagto.vue')
const Cadastro = () => import('../views/Cadastro/index.vue')
const MeusDados = () => import('../views/Cadastro/meus-dados.vue')
const HistoricoPagamentos = () => import('../views/Cadastro/historico-pagamentos.vue')
const CadastroSms = () => import('../views/Cadastro/sms.vue')
const Premios = () => import('../views/Premios/index.vue')
const Ganhadores = () => import('../views/Ganhadores/index.vue')
const ComoParticipar = () => import('../views/ComoParticipar/index.vue')
const Duvidas = () => import('../views/Duvidas/index.vue')
const FaleConosco = () => import('../views/FaleConosco/index.vue')
const Cancelamento = () => import('../views/FaleConosco/cancelar.vue')
const TermoUso = () => import('../views/Termos/termos-uso.vue')
const Regulamento = () => import('../views/Termos/regulamento.vue')
const Politica = () => import('../views/Termos/politica.vue')
const QrCode = () => import('../views/QrCode/index.vue')
const Ticket = () => import('../views/Ticket/index.vue')
const Enquetes = () => import('../views/Enquetes/index.vue')
const EnquetesResultado = () => import('../views/Enquetes/show.vue')
const Publicacoes = () => import('../views/Publicacoes/index.vue')
const AoVivo = () => import('../views/AoVivo/index.vue')
const ParceiroQrCode = () => import('../views/ParceiroQrCode/index.vue')
const Sucesso = () => import('../views/Assinatura/sucesso.vue')
const EditarPerfil = () => import('../views/Cadastro/editar-perfil.vue')

const routes = [{
  path: '/login',
  name: 'Login',
  component: Login
},
{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/home',
  name: 'HomeLogado',
  component: HomeLogado
},
{
  path: '/cadastro',
  name: 'Cadastro',
  component: Cadastro
},
{
  path: '/cadastro-sms',
  name: 'CadastroSms',
  component: CadastroSms
},
{
  path: '/sucesso',
  name: 'Sucesso',
  component: Sucesso,
  meta: {
    hasAuth: true
  }
},
{
  path: '/meus-dados',
  name: 'MeusDados',
  component: MeusDados,
  meta: {
    hasAuth: true
  }
},
{
  path: '/meus-dados/perfil',
  name: 'EditarPerfil',
  component: EditarPerfil,
  meta: {
    hasAuth: true
  }
},
{
  path: '/historico-pagamentos',
  name: 'HistoricoPagamentos',
  component: HistoricoPagamentos,
  meta: {
    hasAuth: true
  }
},
{
  path: '/assinatura',
  name: 'Assinatura',
  component: Assinatura,
  meta: {
    hasAuth: true
  }
},
{
  path: '/forma-pagto',
  name: 'FormaPagto',
  component: FormaPagto,
  meta: {
    hasAuth: true
  }
},
{
  path: '/premios',
  name: 'Premios',
  component: Premios
},
{
  path: '/ganhadores',
  name: 'Ganhadores',
  component: Ganhadores,
  meta: {
    hasAuth: true
  }
},
{
  path: '/como-participar',
  name: 'ComoParticipar',
  component: ComoParticipar
},
{
  path: '/duvidas',
  name: 'Duvidas',
  component: Duvidas
},
{
  path: '/fale-conosco',
  name: 'FaleConosco',
  component: FaleConosco
},
{
  path: '/cancelamento',
  name: 'Cancelamento',
  component: Cancelamento
},
{
  path: '/termos/uso',
  name: 'TermoUso',
  component: TermoUso
},
{
  path: '/termos/regulamento',
  name: 'Regulamento',
  component: Regulamento
},
{
  path: '/termos/politica',
  name: 'Politica',
  component: Politica
},
{
  path: '/qrcode',
  name: 'QrCode',
  component: QrCode,
  meta: {
    hasAuth: true
  }
},
{
  path: '/ticket',
  name: 'Ticket',
  component: Ticket,
  meta: {
    hasAuth: true
  }
},
{
  path: '/enquetes',
  name: 'Enquetes',
  component: Enquetes,
  meta: {
    hasAuth: true
  }
},
{
  path: '/enquetes/resultado',
  name: 'EnquetesResultado',
  component: EnquetesResultado,
  meta: {
    hasAuth: true
  }
},
{
  path: '/publicacoes',
  name: 'Publicacoes',
  component: Publicacoes,
  meta: {
    hasAuth: true
  }
},
{
  path: '/ao-vivo',
  name: 'AoVivo',
  component: AoVivo,
  meta: {
    hasAuth: true
  }
},
{
  path: '/p/:qrcode',
  name: 'ParceiroQrCode',
  component: ParceiroQrCode
},
{
  path: '/:pathMatch(.*)*',
  redirect: {
    name: 'Home'
  }
}
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
